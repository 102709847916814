














































import { getOrderDetail, IDetailData } from '@/api/order';
import { Vue, Component, Provide, ProvideReactive } from 'vue-property-decorator';
import DetailState from './comm/DetailState.vue';
import DetailExpress from './comm/DetailExpress.vue';
import DetailAddress from './comm/DetailAddress.vue';
import OrderProduct from '../comm/OrderProduct.vue';
import OrderButtonGroup from '../comm/OrderButtonGroup.vue';

@Component({
  name: '',
  components: { DetailState, DetailExpress, DetailAddress, OrderButtonGroup, OrderProduct }
})
export default class extends Vue {
  @ProvideReactive()
  orderDetail = {} as IDetailData;

  @Provide('reload')
  async getOrderList() {
    const { payload } = await getOrderDetail(this.orderCode);
    this.orderDetail = payload;
  }
  /* 商品总价 */
  get totalPrice() {
    return this.orderDetail.originalPrice;
  }
  /* 折扣券 折扣金额 */
  get discountsPrice() {
    return this.orderDetail.discounts.reduce((t, i) => {
      return (t += i.discountsPrice);
    }, 0);
  }

  get orderCode() {
    return this.$route.params.orderCode as string;
  }

  created() {
    this.getOrderList();
  }
}
